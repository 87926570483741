import React, { useEffect } from 'react';
import { Grid, Stepper, Step, StepButton, Divider } from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';
import { useHistory } from 'react-router-dom';

import LoadFileEvent from '../LoadFileEvent';
import PairColumns from '../PairColumns';
import GridMassiveEdit from '../GridMassiveEdit';
import MassiveSummary from '../MassiveSummary';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { ASSETS } from '../../../constants/paths';
import ModalMessage from '../../../Components/Feature/ModalMessage';
import { useTranslation } from 'react-i18next';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';

const UploadContainerEvent = (props) => {
  const { t } = useTranslation();
  let history = useHistory();

  const steps = [
    t("stepByStep.massive.eventStep.loadFileEvent"),
    t("stepByStep.massive.eventStep.pairColumns"),
    t("stepByStep.massive.eventStep.gridMassiveEdit"),
    t("stepByStep.massive.eventStep.massiveSummary"),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [assets, setAssets] = React.useState([]);
  const [maxStepAvailable, setMaxStepAvailable] = React.useState(0);
  const [pairColumns, setPairColumns] = React.useState([]);
  const [waitModal, setWaitModal] = React.useState(false);
  const [infoStep, setInfoStep] = React.useState(['', '', '', '']);
  const [fileInfo, setFileInfo] = React.useState('');
  const [excelUploadId, setExcelUploadId] = React.useState(-1);
  const [dataList, setDataList] = React.useState({});
  //const [completed] = React.useState({});
  const [amountErrorsInitial, setAmountErrorsInitial] = React.useState(null);
  const globalContext = useGlobalContext();
  const { userData } = globalContext;
  const timeZone = userData?.userData?.timezone;

  useEffect(() => {
    setActiveStep(0);
    updateDataState('', 0);
    // TODO: DELETE THIS
    /*setExcelUploadId(82);
    setActiveStep(2);*/

    /*   setFileInfo({
      fileId: 'cb48b076-dff1-4ed6-a827-a7ca3a049a99',
      originalName: 'errorColumnas.csv',
      storageName: 'aHcPuRaVheAQY_J6Kz9Q-',
      checksum: 'ce41bc66b76f33aea5ed86b13a8bb485',
      publicFileURI: 'http://localhost:2022/file/download/cb48b076-dff1-4ed6-a827-a7ca3a049a99',
      repositoryId: 1,
    });
    setActiveStep(1); */
  }, []);

  useEffect(() => {
    // console.log(activeStep, infoStep);
  }, [activeStep]);

  const handleStep = (step) => () => {
    /* if (activeStep > step && infoStep[step] !== '') {
      // bring the info from that state
      switch (step) {
        case 0:
          break;
        case 1:
          setFileInfo(infoStep[step]);
          break;
        case 2:
          setExcelUploadId(infoStep[step]);
          break;
        case 3:
          setDataList(infoStep[step]);
          break;
      }
    } */
    if (step < maxStepAvailable) setActiveStep(step);
  };

  const updateDataState = (data, index, index2 = -1) => {
    let newArr = [...infoStep]; // copying the old datas array
    newArr[index] = data;
    if (index2 !== -1) newArr[index2] = data;
    setInfoStep(newArr);
  };

  const handleUploadFile = (file) => {
    setFileInfo(file);
    setActiveStep(1);
    updateDataState(file, 1);

    setMaxStepAvailable(1);
  };

  const handleAllPair = (id) => {
    resetMassiveEditState();

    setExcelUploadId(id);
    setActiveStep(2);
    updateDataState(id, 2);

    setMaxStepAvailable(2);
  };

  const handleSubmit = (list) => {
    setDataList(list);
    setActiveStep(3);
    updateDataState(list, 3);

    setMaxStepAvailable(3);
  };

  const handleAmountErrorsInitial = (val) => {
    if (amountErrorsInitial == null) {
      setAmountErrorsInitial(val);
    }
  };

  const handleDataChange = (dataList, dataPairColumns) => {
    // dataList is the setAssets in the MassiveEdit
    setAssets(dataList);
    setPairColumns(dataPairColumns);
  };

  const resetMassiveEditState = () => {
    setAssets([]);
    setPairColumns([]);
    setMaxStepAvailable(1);
  };

  const handleFinalSubmit = () => {
    let dataListTemp = [];

    if (props.action === 'update') {
      dataList.map((data) => {
        let dataTemp = { ...data };
        delete dataTemp.id;
        let dataTempNoValuesFilter = {};
        for (let key of Object.entries(dataTemp)) {
          if (dataTemp[key[0]] != null) {
            dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
          }
        }
        dataListTemp.push({ ...dataTempNoValuesFilter, pk: data._pk_ });
      });
    }

    let attr =
      globalContext.getSessionDataByKey('models')[props.globalModel].attributes;
    if (props.action === 'add') {
      dataListTemp = [...dataList];
    }

    let dataListTempLabels = [];
    dataListTemp.map((value, index) => {
      let labelsTemp = {};
      Object.entries(value).map((it) => {
        const rowAttr = attr.find(
          (x) =>
            x.name === it[0] &&
            (x.type === 'select' || x.type === 'date' || x.type === 'dateTime' || x.type === 'multiselect')
        );
        if (rowAttr) {
          if (rowAttr.type === 'select') {
            let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
            if (selectedItem) {
              labelsTemp[rowAttr.name] = selectedItem.label;
            }
          }
          if (rowAttr.type === 'multiselect') {
            let selectedItemLabels = [];
            it[1].split(',').map((i) => {
              let item = rowAttr.values.find((x) => x.key === i);
              if (item) {
                selectedItemLabels.push(item.label);
              }
            });
            labelsTemp[rowAttr.name] = selectedItemLabels.join(',');
          }
          if (rowAttr.type === 'date' || rowAttr.type === 'dateTime') {
            const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
            labelsTemp[rowAttr.name] = dateLabel;
            dataListTemp[index][it[0]] = dateValue;
          }
        }
        // TODO: fix provisorio agregar _event_date_ al modelo para poder controlar mejor las fechas
        if (it[0] === '_eventDate_') {
          const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
          labelsTemp[it[0]] = dateLabel;
          dataListTemp[index][it[0]] = dateValue;
        }
      });
      dataListTempLabels.push(labelsTemp);
    });

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {
        //date:eventDate,
        //comment:eventComment
      },
      action: props.action,
      pk: props.pk,
      objectType: props.objectType, //"asset",
      pkObjectType: props.pkObjectType, //assetContext.objectTypeSubType,
      pkReferer: props.pkReferer,
      holder: props.parentId, //assetContext.parentId,
      temporalDetailParentId: props.temporalDetailParentId, // -1
      globalModel: props.globalModel, //assetContext.model,
      actionContext: {context:'massive_file_form', eventType:props.eventType}
    };

    ApiFetch(
    'objects/add/massive',
    globalContext,
    true,
      async (res) => {
        if (res.status === 200) {
          props.onUploadOk(res);
          history.push({
            pathname: ASSETS,
            state: {
              toast: {
                mode: 'update',
                action: 'updated_massive',
              },
            },
          });
        } else {
          props.onUploadError(res);
          setWaitModal(false);
        }
      },
      (error) => {
        props.onUploadError(error);
      },
      {
        method: 'POST',
        body: JSON.stringify(dataObj),
      }
    );

};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-0">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < maxStepAvailable}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Divider
          variant="middle"
          className="mt-4"
          style={{ marginBottom: '5px' }}
        />
      </Grid>
      {activeStep === 0 && (
        <LoadFileEvent
          onUploadFile={handleUploadFile}
          {...props}
        />
      )}
      {activeStep === 1 && (
        <PairColumns
          globalModel={props.globalModel}
          fileInfo={fileInfo}
          entityMode={props.entityMode}
          eventType={props.eventType}
          onAllPair={handleAllPair}></PairColumns>
      )}
      {activeStep === 2 && (
        <GridMassiveEdit
          data={assets}
          mode={'Edit'}
          manualUpload={true}
          entityMode={props.entityMode}
          eventType={props.eventType}
          pairColumns={pairColumns}
          globalModel={props.globalModel}
          excelUploadId={excelUploadId}
          onDataChange={handleDataChange}
          pkObjectType={props.pkObjectType}
          enableEventDate={true}
          onSubmit={handleSubmit}
          onSetAmountErrorsInitial={
            handleAmountErrorsInitial
          }></GridMassiveEdit>
      )}
      {activeStep === 3 && (
        <MassiveSummary
          showControls={true}
          dataList={dataList}
          globalModel={props.globalModel}
          onFinalSubmit={handleFinalSubmit}
          amountErrorsInitial={amountErrorsInitial}></MassiveSummary>
      )}

      <ModalMessage
        open={waitModal}
        toggleModal={() => {}}
        modalText={t('component.processing')}
      />
    </Grid>
  );
};

export default UploadContainerEvent;
