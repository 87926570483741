import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  MenuItem,
  Select,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Typography,
  Card,
  Chip,
  Box,
} from '@mui/material';

import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';

function PairColumns(props) {
  const { t } = useTranslation();
  const [areAllMandatoryPair, setAreAllMandatoryPair] = useState(false);
  const [fixedRows, setFixedRows] = useState([]);
  const [notPairColumns, setNotPairColumns] = useState([]);
  const [labelCols, setLabelCols] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const globalContext = useGlobalContext();
  let models = globalContext.getSessionDataByKey('models');
  const { selectedAsset, setHeaderSubtitle } =
    useGlobalContext();

  const {isResUploadOk, resJson, missingColumns, setMissingColumns, onAllPair} = props

  useEffect(() => {
    let model = selectedAsset.id;
    let labelColsTemp = {};
    for (let attr of models[model].attributes) {
      labelColsTemp[attr.name] = attr.label;
    }
    labelColsTemp['_pk_'] = 'Id';
    labelColsTemp['_eventDate_'] = 'Fecha del Evento';
    labelColsTemp['_eventComment_'] = 'Comentario';

    setLabelCols(labelColsTemp);
    setHeaderSubtitle("stepByStep.massive.step2.loadSubtitle");
    setTimeout(() => {
      setShowTable(true);
    }, 2000)
  }, []);

  useEffect(() => {
    if (isResUploadOk) {
      loadDataOnlyOnce();
    }
  }, [isResUploadOk]);

  useEffect(() => {
    if (isResUploadOk) {
      isAllMandatoryPair(resJson.allColumns, resJson.pairColumns);
      updatePairsDB(fixedRows);

      detectMissingColumns(fixedRows);
    }
  }, [isResUploadOk, fixedRows]);

  const loadDataOnlyOnce = () => {
    if (resJson !== undefined) {
      resJson.excelColumnsDetected.map((row, index) => {
        let defValue = getDefaultValue(
          resJson.pairColumns,
          resJson.allColumns,
          resJson.suggestedColumnsForErrors,
          row,
          index
        );

        if (defValue !== '') {
          let data = fixedRows;
          data = addColumn(data, resJson.allColumns[defValue].name, row);
          setFixedRows(data);
        }
      });
      isAllMandatoryPair(resJson.allColumns, resJson.pairColumns);
    }
  };

  const addColumn = (arr, columnName, excelColumnName) => {
    const fRow = { originColumn: columnName, excelColumn: excelColumnName };
    const index = arr.findIndex((data) => data.excelColumn === excelColumnName);
    if (index !== -1) {
      arr[index] = fRow;
    } else {
      arr.push(fRow);
    }
    return arr;
  };

  const getDefaultValue = (pairColumns, allColumns, suggestedColumnsForErrors, row, index) => {
    let indexColumn = pairColumns.findIndex(
      (data) => data.excelColumn.toLowerCase() === row.toLowerCase()
    );

    if (indexColumn !== -1) {
      return allColumns.findIndex(
        (data) => data.name.toLowerCase() === pairColumns[indexColumn].originColumn.toLowerCase()
      );
    } else {
      indexColumn = suggestedColumnsForErrors.findIndex(
        (data) => data.columnUploadExcelName.toLowerCase() === row.toLowerCase()
      );
      if (indexColumn !== -1) {
        return allColumns.findIndex(
          (data) =>
            data.name.toLowerCase() ===
            suggestedColumnsForErrors[indexColumn].columnSuggestExcelName.toLowerCase()
        );
      } else {
        return '';
      }
    }
  };

  const isAllMandatoryPair = (allColumns, pairColumns) => {
    let notPairColumns = allColumns.filter((rowCol) => {
      if (rowCol.mandatory === true) {
        let index = fixedRows.findIndex(
          (data) => data.originColumn.toLowerCase() === rowCol.name.toLowerCase()
        );
        if (index === -1) {
          return true;
        }
        return false;
      }
    });

    let ret = notPairColumns.length === 0;
    setNotPairColumns(notPairColumns);
    setAreAllMandatoryPair(ret);
    return ret;
  };

  const detectMissingColumns = (data) => {
    let notColumns = resJson.allColumns
      .filter((row) => data.every((col) => col.originColumn !== row.name))
      .map((row) => row.name);
    setMissingColumns(notColumns);
  };

  const handleChangeDropbox = (event) => {
    let data = fixedRows;
    data = addColumn(data, resJson.allColumns[event.target.value].name, event.target.name);
    setFixedRows(data);

    detectMissingColumns(data);

    updatePairsDB(data);
  };

  const updatePairsDB = (data) => {
    let allPair = isAllMandatoryPair(resJson.allColumns, resJson.pairColumns);

    const postData = {
      fixedColumns: data,
      excelUploadId: resJson.ExcelUploadId,
      isAllpair: allPair,
    };

    ApiFetch(
      'parseExcel/updatePairs',
      globalContext,
      true,
      async (result) => { },
      (error) => {
        console.log('error updatePairsDB', error);
      },
      {
        method: 'PUT',
        body: JSON.stringify(postData),
      }
    );
  };

  const handleSubmitColumns = (event) => {
    if (!areAllMandatoryPair) {
      alert(t("stepByStep.massive.step2.modal.notAllMandatoryPair"));
      return false;
    }

    let excelUploadId = resJson.ExcelUploadId;
    onAllPair(excelUploadId);
  };

  const renderHeader = (params, row, defValue) => {
    if (defValue.toString().length === 0) {
      if (defValue === '') defValue = '-1';
    }

    if (resJson && resJson.allColumns)
      return (
        <Select
          style={{ width: '100%' }}
          name={row}
          id={row + defValue}
          defaultValue={defValue}
          label={row}
          width="100%"
          onChange={handleChangeDropbox}>
          <MenuItem value={'-1'} disabled key={-1}>
            {t("stepByStep.massive.button.select")}
          </MenuItem>
          {resJson.allColumns.map((rowCol, index) => (
            <MenuItem value={index} key={index}>
              {labelCols[rowCol.name]} {rowCol.mandatory ? ' *' : ''}
            </MenuItem>
          ))}
        </Select>
      );
    else return <div></div>;
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className="d-flex flex-column flex p-3" style={{ height: "100%" }}>
            <Typography variant="h5" class="mb-2">
              {t("stepByStep.massive.step2.columns.mandatory")} (
              {resJson?.mandatoryColumns ? resJson?.mandatoryColumns.length : 0})
            </Typography>
            <Grid item xs={12}>
              {resJson?.mandatoryColumns?.map((data) => {
                return <Chip label={labelCols[data]} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} />
              })}
            </Grid>
          </Card>
        </Grid>
        {(false) &&
          <Grid item xs={4}>
            <Card className="d-flex flex-column flex p-3">
              <Typography variant="h5" class="mb-2">
                {t("stepByStep.massive.step2.columns.notDetected")} ({missingColumns ? missingColumns.length : 0})
              </Typography>
              <div className="d-flex mt-2">
                {missingColumns?.map((data) => labelCols[data]).join(', ')}
              </div>
            </Card>
          </Grid>
        }
        <Grid item xs={6}>
          <Card className="d-flex flex-column flex p-3" style={{ height: "100%" }}>
            <Typography variant="h5" class="mb-2">
              {t("stepByStep.massive.step2.columns.mandatoryMissing")} ({notPairColumns?.length})
            </Typography>
            {notPairColumns.length === 0
              ? t("stepByStep.massive.step2.columns.allAssociated")
              : <Grid item xs={12}>
                {
                  notPairColumns?.map((data) => {
                    return <Chip label={labelCols[data.name]} color="primary" sx={{ mr: 1 }} style={{ margin: '2px' }} />
                  })
                }
              </Grid>
            }
          </Card>
        </Grid>
        {(showTable) &&
          <Grid item xs={12} style={{ overflow: "auto", maxWidth: "75vw" }}>
            <Table aria-label="simple table" fixedHeader={false}>
              <TableBody>
                {resJson?.excelColumnsDetected && (
                  <TableRow>
                    {resJson.excelColumnsDetected.map((row, index) => {
                      let defValue = getDefaultValue(
                        resJson.pairColumns,
                        resJson.allColumns,
                        resJson.suggestedColumnsForErrors,
                        row,
                        index
                      );
                      return (
                        <TableCell key={index}>
                          {renderHeader({}, row, defValue)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}

                {resJson?.excelColumnsDetected && (
                  <TableRow className="title">
                    {resJson.excelColumnsDetected.map((row, index) => (
                      <TableCell>
                        <b>{row}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                {resJson?.specificRows?.map((row, index) => {
                  return (
                    <TableRow>
                      {resJson.excelColumnsDetected.map((rowCol, index) => (
                        <TableCell>{row[rowCol]}</TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        }

        <Grid item xs={12} className="d-flex justify-center">
          {isResUploadOk && areAllMandatoryPair && (
            <div>
              <Button variant="contained" onClick={handleSubmitColumns}>
                {t("stepByStep.massive.button.continue")}
              </Button>
            </div>
          )}
          {isResUploadOk && !areAllMandatoryPair && (
            <div>
              <Button variant="contained" disabled>
                {t("stepByStep.massive.button.continue")}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PairColumns;
