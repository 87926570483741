import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Divider, CircularProgress, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import useGlobalContext from '../../Hooks/useGlobalContext';
import useThemeContext from '../../Hooks/useThemeContext';
import { ApiFetch } from '../../Utils/ApiFetch';
import { HOME, IMAGES_PATH, USER_CHANGE_PASSWORD } from '../../constants/paths';
import BaseBasicForm from '../../Layout/containers/BaseBasicForm';
import leftIcon from '../../Images/stepbystep/lefticons/bienvenido.png';
import Version from '../../Components/Generic/Version';
import { ReValidateButton } from './ReValidateButton';
import LanguageSelect from '../../Components/LanguageSelect';
import { getInitialLanguage } from '../../Helpers/translation';
import { ThemeContext } from '../../Contexts/themeContext';

const { REACT_APP_URL } = process.env;

const Login = () => {
  const globalContext = useGlobalContext();
  const { swithMenuMode } = useThemeContext();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [reValidateUser, setReValidateUser] = useState(false);
  const { logoTenant } = useContext(ThemeContext);

  let noUserMsg = "signIn.noUserOrigino";
  let signUpPath = "/register";

  // Para sandBox se cambia mensaje y ruta
  if (process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true') {
    noUserMsg = "signIn.noUserSandbox";
    signUpPath = "/public/signup/sandbox/create";
  }
  let history = useHistory();
  //const theme = createTheme();

  useEffect(() => {
    const storedLang = getInitialLanguage();
    if (storedLang && storedLang !== globalContext.selectedLang) {
      globalContext.handleChangeLanguage({ target: { value: storedLang } });
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true') {
      bindCredentialsSandabox();
    }
  }, []);

  const bindCredentialsSandabox = () => {
    let userSandbox = localStorage.getItem("user_sandbox");
    let passwordSandbox = localStorage.getItem("password_sandbox");
    if (userSandbox !== null && passwordSandbox !== null) {
      setUser(userSandbox);
      setPassword(passwordSandbox);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    setIsLogin(true);
    setReValidateUser(false)
    signIn();
    async function signIn() {
      const { token, refreshToken, reValidate } = await getApiTokens();
      if(!reValidate){
        ApiFetch(
          'users/getInfo',
          globalContext,
          false,
          async (body) => {
            setIsLogin(false);
            const user = await body.json();
            globalContext.login(token, user, refreshToken);
            swithMenuMode('HOME');
            history.push(HOME);
          },
          (error) => {
            setIsLogin(false);
          },
          {
            headers: new Headers({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          }
        );
      }
    }

    function getApiTokens() {
      setIsLogin(true);
      setShowError(false);
      return new Promise((resolve, reject) => {
        ApiFetch(
          'users/signIn',
          globalContext,
          false,
          (body) => {
            const token = async () => {
              const jsonResponse = await body.json();
              if( jsonResponse.reValidate ){
                setReValidateUser(true)
                return {
                  token: '',
                  refreshToken: '',
                  reValidate: true
                };
              }else{
                return {
                  token: jsonResponse.token,
                  refreshToken: jsonResponse.refreshToken,
                };
              }
            };
            setIsLogin(false);
            resolve(token());
          },
          async (error) => {
            setIsLogin(false);
            setShowError(true);
            const res = await error.json();
            setMsgError(res.error);
          },
          {
            method: 'POST',
            body: JSON.stringify({ email: data.get('email'), password: data.get('password') }),
          }
        );
      });
    }
  };

  return (
    <BaseBasicForm text={globalContext.t("login.title.welcome")} leftIcon={leftIcon} showOriginoImage="true">
      <Box display="flex" justifyContent="flex-end" width="100%" sx={{
        mr: {lg: 3, xl: 10 },
        mb: 2,
      }}>
        <LanguageSelect />
      </Box>
      <Typography variant="h4" component="h2" color="text.primary" className="mb-0">
        {globalContext.t("signIn.title")}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          id="email"
          name="email"
          label="Email"
          className="mt-4"
          autoComplete="email"
          value={user}
          onChange={(val) => { setUser(val.target.value) }}
          fullWidth
        />
        <TextField
          id="password"
          label="Contraseña"
          type="password"
          name="password"
          value={password}
          onChange={(val) => { setPassword(val.target.value) }}
          helperText={globalContext.t("signIn.passwordHelperText")}
          className="mt-2"
          autoComplete="current-password"
          fullWidth
        />
        <br />
        <br />

        <Box textAlign='center'>
          <Button variant="contained" size="large" disabled={isLogin} className="mb-4" type="submit">
            {isLogin &&
              <><CircularProgress style={{ color: "black" }} size={14} />&nbsp;</>
            }
            {globalContext.t("login.button.signIn")}
          </Button>
          { reValidateUser && 
            <>
              <ReValidateButton
                setShowError={(e)=>setShowError(e)}
                setMsgError={(e)=>setMsgError(e)}
                isLogin={true}
                email={user}
              />
            </>
          }  
          <br />
          <br />
          <Typography>
            {globalContext.t("login.option.forgotPassword")} &nbsp;
            <Link href={`${REACT_APP_URL}${USER_CHANGE_PASSWORD.substring(1)}`}>
              {globalContext.t("login.link.resetPassword")}
            </Link>
          </Typography>
          {(showError) &&
            <>
              <span style={{ textAlign: "center", color: "red" }}>
                <br /><br />{globalContext.t("signIn.errorMsg." + msgError)}</span>
              {/* <Typography variant="title1" component="h6" color="text.secondary" className="mt-0" textAlign='center'>
                <br />
                Para reestrablecer tu contraseña contactanos a <a href='mailTo:subteno@origino.io'>subteno@origino.io</a>
              </Typography> */}
            </>
          }
        </Box>

        <Divider sx={{ borderBottomWidth: 3, mt: 5 }} />

        <Typography variant="title1" component="h5" color="text.primary" className="mt-0" textAlign='center'>
          <Box
            style={{marginBottom: 2}}
          >
            <Typography variant="body1" component="p">
              {globalContext.t(noUserMsg + ".0")}
              <b>{globalContext.t(noUserMsg + ".1")}</b>
              {globalContext.t(noUserMsg + ".2")}
            </Typography>
          </Box>
          <Box>
            <img style={{ width: "135px", marginLeft: "5px", marginTop: "15px"}} src={`${IMAGES_PATH}/origino_logo.png`} alt=""/>
            {logoTenant && 
              <img style={{ maxHeight:"30px", width: "auto", marginLeft: "15px", marginTop: "15px"}} src={logoTenant} alt=""/>
            }
          </Box>
        </Typography>
        <Box textAlign='center'>
          <Button
            onClick={() => { history.push(signUpPath) }}
            variant="outlined" className="mt-4" size="large" type="button">
            {globalContext.t("login.button.createAccount")}
          </Button>
        </Box>
          <Version></Version>
      </Box>
    </BaseBasicForm>
  );
};

export default Login;
