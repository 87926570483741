/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch } from '../../Utils/ApiFetch';
import {
  Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import iconStampedNFT from '../../Images/nft_stamped.png';
import iconNotStampedNFT from '../../Images/nft_notstamped.png';
import BasicFilter from './BasicFilter'
import { Checkbox, Grid } from '@mui/material';
import SplitButton from './SliptButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next'
import PopoverErrorMesssage from './../../Layout/components/verificacion/PopoverErrorMessage';
import BasicDialog from '../BasicDialog';
import Loading from './Loading';
import ExcelExport from '../../Utils/ExportToExcel';
import { dateSanitizer } from '../../Helpers/dateFormater';
import { stateRules } from '../../Helpers/assetHelper';
import { constPaginationDataGrid } from '../../constants/paginationDataGrid';

function LargeGridData(props) {
  const { t } = useTranslation();
  const { selectedBox, selectedAsset, updateSessionData, getSessionDataByKey, pageSizeDGs, setPageSizeDGs } = useGlobalContext();
  const [open, setOpen] = useState(false)
  const [pageSize] = useState(pageSizeDGs);
  const [isInit, setIsInit] = useState(false);
  const [columns, setColumns] = React.useState([]);
  const [columnsExport, setColumnsExport] = useState([])
  const [errorCell, setErrorCell] = React.useState(null);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const globalContext = useGlobalContext()
  const [rows, setRows] = useState([]);
  const defaultPagination = { field: 'id', sort: 'desc' }
  const [rowCount, setRowCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsState, setRowsState] = useState({ page: 0, pageSize: pageSize });
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  const [sortModel, setSortModel] = useState([defaultPagination]);
  const [filterValues, setFilterValues] = useState([]);
  const [filterValuesLoaded, setFilterValuesLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('')  //'all','none','invert','some'
  const [someValue, setSomeValue] = useState(20) // se usa cuando selectedOption==='some'
  const [selectedRows, setSelectedRows] = useState([])
  const [allRows, setAllRows] = useState([])
  const [exportLoading, setExportLoading] = useState(false)

  const models = JSON.parse(localStorage.getItem('SESSION_DATA'))?.models[selectedAsset.id]

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState,
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    setRowCount(undefined);
    loadServerRowsWithoutFilters(0, 5);
    setSelectedRows([]);
  }, [props.url]);

  const closeError = () => {
    setErrorCell(null);
    setErrorOpen(false)
  };

  const loadAllFilteredRows = () => {
    setExportLoading(true)
    ApiFetch(
      (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
      `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
      `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}${(props?.isLogicalDeletedPage) ? '/deleted' : ''}`,
      globalContext,
      true,
      async (body) => {
        let resJson = await body.json()
        setAllRows(resJson.res)
        setExportLoading(false)
      },
      error => {
        setExportLoading(false)
      },
      {
        method: "POST",
        body: JSON.stringify({ 'filters': filterValues }),
      }
    );
  }

  // tododromero: cambiar endpoint
  const loadServerRowsWithoutFilters = async (page, pageSize) => {
    const urlFetch = (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
      `${props.url}/${page}/${pageSize}/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
      `${props.url}/${page}/${pageSize}/${sortModel[0].field}/${sortModel[0].sort}${(props?.isLogicalDeletedPage) ? '/deleted' : ''}`

    if (filterValuesLoaded || true) {
      ApiFetch(
        urlFetch,
        globalContext,
        true,
        async (body) => {
          try {
            let resJson = await body.json()
            props.onChangeRowCount(parseInt(resJson.resTotal))
          } catch (e) {
            //
          }
        },
        error => {

        },
        {
          method: "POST",
          body: JSON.stringify({ 'filters': [] }),
        }
      );
    }
  }

  const loadServerRows = async (page, pageSize) => {
    if (filterValuesLoaded) {
      let active = true;

      setIsLoading(true);
      setRowCount(undefined);
      if (sortModel.length === 0) {
        sortModel.push(defaultPagination)
      }

      const urlFetch = (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
        `${props.url}/${page}/${pageSize}/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
        `${props.url}/${page}/${pageSize}/${sortModel[0].field}/${sortModel[0].sort}${(props?.isLogicalDeletedPage) ? '/deleted' : ''}`;
      ApiFetch(
        urlFetch,
        globalContext,
        true,
        async (body) => {
          try {
            let resJson = await body.json()

            if (!active) { return; }

            setRows(resJson.res);

            if (selectedBox.id !== -1) {
              setRowCount(resJson.resTotal)
            }

            setIsLoading(false);


            return () => { active = false; }
          } catch (e) {
            //
          }
        },
        error => {

        },
        {
          method: "POST",
          body: JSON.stringify({ 'filters': filterValues }),
        }
      );
    }
  }

  const loadServerRowsWithoutPaginator = async () => {
    if (filterValuesLoaded) {
      let active = true;

      setIsLoading(true);
      setRowCount(undefined);
      if (sortModel.length === 0) {
        sortModel.push(defaultPagination)
      }


      ApiFetch(
        (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
        `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
        `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/keyvalues`,
        globalContext,
        true,
        async (body) => {
          let resJson = await body.json()
          if (!active) { return; }

          let rowsToEvent = [];
          for (let tempRow of resJson.res) {
            if (selectedRows.includes(tempRow.id.toString())) {
              rowsToEvent.push(tempRow);
            }
          }

          props?.exportFilterAction({
            exportFileDataConfig: props?.exportFilterDataConfig,
            rows: rowsToEvent
          });

          setIsLoading(false);

        },
        error => {

        },
        {
          method: "POST",
          //body: JSON.stringify({ 'filters':filterValues}),
          body: JSON.stringify({ 'filters': [] }),
        }
      );
    }
  }

  useEffect(() => {
    if (props.onSelectedRowsChange !== undefined) {
      props.onSelectedRowsChange(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (props.fields.length === 0) return;
    let ret = [];
    if (props.actions) {
      ret.push({
        field: 'selection', headerName: '', sortable: false, width: 10, renderCell: (
          (params) => {
            let txPaused = false;
            let txAssetEvent = false;
            let txLogicalDelete = false;

            if (params.row['paused'] != null) {
              if (params.row['paused']) {
                txPaused = true;
              }
            }

            if(props.event?.assetDisabledToEvent){
              props.event?.assetDisabledToEvent.forEach(condition => {
                if(!txAssetEvent && selectedBox.subtype === condition.box && params.row[condition.params] === condition.value){
                  txAssetEvent = true;
                }
              })
            }

            if (params.row['logical_deleted'] === 1) {
              txLogicalDelete = true;
            }

            return (
              <Checkbox
                disabled={txPaused || txAssetEvent || txLogicalDelete}
                checked={selectedRows.includes(params.id)}
                onChange={() => { handleRowSelection(params.id) }}
              />
            )
          }
        )
      })

      ret.push({ field: 'actions', headerName: '', minWidth: "30", width: "30", sortable: false, renderCell: props.actions });
    }

    if (props.qrMenu && (models?.QrHide === undefined || !models?.QrHide)) {
      ret.push({ field: 'qr', headerName: '', minWidth: "30", width: "30", sortable: false, renderCell: props.qrMenu });
    }

    ret.push({ field: 'id', headerName: 'Id', hide: true });
    for (let field of props.fields) {
      let flagAdd = false;
      if (field.permissions.list[props.parentType].includes(parseInt(props.parentTypeSubType)) === true) {
        flagAdd = true;
      }

      if (field.hideInList){
        if(field.listInEvent?.includes(props.event?.eventType) || field.listInPath?.includes(props.statePath))
          flagAdd = true;
        else flagAdd = false;
      }

      if (flagAdd) {
        if (field.type === 'file') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 190,
            renderCell: (params) => {

              let image = <></>
              if (params.row[field.name] != null && params.row[field.name] !== "") {
                let file = JSON.parse(params.row[field.name]);
                if (file?.originalName) {
                  let originalNameSplit = file.originalName.split(".");
                  if (['JPG', 'PNG', 'JPEG', 'TIFF', 'BMP'].includes(originalNameSplit[originalNameSplit.length - 1].toUpperCase())) {
                    image = <img style={{ maxWidth: "30px", borderStyle: "dotted", borderWidth: "1px", verticalAlign: "middle", marginRight: "5px" }} src={file.publicFileURI} alt=""></img>
                  }
                }
                const onDownload = () => {
                  const link = document.createElement("a");
                  //link.download = `download.txt`;
                  link.href = file.publicFileURI;
                  link.click();
                };
                if (params.row[field.name] != null && params.row[field.name] !== "") {
                  return (
                    <div>
                      {image}
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="small"
                        startIcon={<FontAwesomeIcon icon={faDownload} />}
                        onClick={() => { onDownload() }}
                        style={{ padding: "5px", margin: "5px", fontSize: "12px" }}
                      >
                        {t("largeGrid.button.download")}
                      </Button>

                    </div>
                  )
                }
              }


            }
          })
        } else if (field.type === 'nft_link') {
          ret.push({
            field: 'Id', //field.name,
            headerName: 'Id',
            width: 100,
            renderCell: (params) => {

              if (params.row[field.name] === "" || params.row[field.name] == null) {
                return <>
                  <img alt=""
                    onClick={
                      (e) => {
                        setErrorOpen(true);
                        setErrorCell(e.currentTarget);
                      }
                    }
                    style={{ width: "30px", marginLeft: "2px", marginRight: "5px", cursor: "pointer" }} src={iconNotStampedNFT} />
                  {params.row['id']}
                </>;
              } else {
                return <>
                  <a target="_BLANK" style={{ marginLeft: "2px", marginRight: "5px" }} href={params.row[field.name]} rel="noreferrer">
                    <img alt="" style={{ width: "30px" }} src={iconStampedNFT} /></a>{params.row['id']}
                </>;
              }
            }
          })
        } else if (field.type === 'paused') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 75,
            fieldType: field.type,
            renderCell: (params) => {
              if (params.row.paused) {
                return <Button
                  onClick={
                    (e) => {
                      setErrorOpen(true);
                      setErrorCell(e.currentTarget);
                    }
                  }
                  style={{ 
                    padding: "0px",
                    paddingTop:'0.2rem',
                    margin: "0px",
                    fontSize: "11px",
                    height: '25px',
                    width: '90%'
                  }}
                  size="small" color="secondary" variant="contained">{t("largeGrid.button.paused")}</Button>
              } else {
                return <Button
                  style={{ 
                    padding: "0px",
                    paddingTop:'0.2rem',
                    margin: "0px",
                    fontSize: "11px",
                    height: '25px',
                    width: '90%'
                  }}
                  size="small" color="primary" variant="contained">{t("largeGrid.button.alive")}</Button>
              }
            }
          })

        } else if (field.type === 'date') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150,
            fieldType: field.type,
            valueFormatter: (params) => dateSanitizer(params?.value)
          })} else if (field.type === 'checkbox' ) {
          ret.push({
            field: field.name, headerName: field.label, sortable: true, width: '120', renderCell: (
              (params) => {
                return (
                  <Checkbox
                    sx={{width: '100%'}}
                    id={params.id}
                    disabled={true}
                    checked={params.row[field.name] === "true" ? true : false}
                  />
                )
              }
            )
          })
        } else if (field.type === 'select' && field.buttonInGrid) {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 140,
            justifyContent: 'center',
            renderCell: (params) => {
              let objAssetColor = null;
              let fontAssetColor = null;
              let displayValue = params.row[field.name];

              if (field.assetRules) {
                const model = selectedAsset.id;
                const stateRuleFunc = stateRules[model];
                
                if (stateRuleFunc) {
                  displayValue = stateRuleFunc(params.row, false);
                }
              }

              field.colorsButton.forEach(asset => {
                if (!objAssetColor && asset.label === displayValue) {
                  objAssetColor = asset.color
                  fontAssetColor = asset.fontColor || 'black'
                }
              })

              if (!objAssetColor) objAssetColor = '#76CC81'
              if (!fontAssetColor) fontAssetColor = 'black';

              let txPaused = false
              if (field.priorPausedSelection && params.row['paused']) {
                txPaused = true
              }

              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                  <Button
                  color="primary"
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "11px",
                    paddingTop: '0.2rem',
                    background: txPaused ? '#76CC81' : objAssetColor,
                    color:  txPaused ? 'white' : fontAssetColor,
                    height: '25px',
                    width: '80%'
                  }}
                  size='medium'
                  variant="contained"
                >
                  {txPaused ? t("largeGrid.button.paused") : displayValue}
                </Button>
              </div>
              )
            }
          })
        } else if (field.type === 'none') {
          // field.type none no muestra la columna en la tabla de assets.
        } else {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150
          })
        }
      }
    }

    setColumns(ret);
    ret.map(ret => ret.active = true)
    setColumnsExport(ret.filter(r => r.field !== 'selection' && r.field !== 'actions' && r.field !== 'Id' && r.field !== 'qr' && r.field !== 'photofile'));
    setIsInit(true);
  }, [props.fields, props.parentTypeSubType, selectedRows]);

  useEffect(async () => {
    if(props.reloadAfterEvent) {
      await loadServerRows(rowsState.page, rowsState.pageSize, sortModel[0]);
    }
  }, [props.reloadAfterEvent])

  useEffect(async () => {
    await loadServerRows(rowsState.page, rowsState.pageSize, sortModel[0])
  }, [rowsState, props.url, filterValues, filterValuesLoaded]);

  useEffect(async () => {
    setRowsState({ ...rowsState, page: 0 })
    await loadServerRows(rowsState.page, rowsState.pageSize, sortModel[0])
  }, [sortModel]);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  useEffect(() => {
    handleRowSelection()
    setSelectedOption('')
  }, [selectedOption])

  // FSLC:  FILAS SELECCIONADAS EN LOCAL STORAGE
  //================================================================================
  useEffect(async () => {
    if (rows.length > 0) { // condición para que no corra en la inicialización
      let newObject = {}
      let key = `selectedAssets_${selectedAsset.id}_` + (props.sessionContext === undefined ? '' : props.sessionContext)
      newObject[key] = { selectedRows: selectedRows }
      await updateSessionData(newObject);
    }
  }, [selectedRows]);

  useEffect(async () => {
    if (props.preSelectedRows !== undefined) {
      setSelectedRows(props.preSelectedRows);
    } else {
      let key = `selectedAssets_${selectedAsset.id}_` + (props.sessionContext === undefined ? '' : props.sessionContext)
      let selectedRowsFromSession = await getSessionDataByKey(key);
      if (selectedRowsFromSession !== undefined) {
        setSelectedRows(selectedRowsFromSession.selectedRows);
      }
    }
  }, []);

  // FIN FSLC
  const handleRowSelection = (value) => {
    if (value === undefined) {
      switch (selectedOption) {
        case 'all':
          setIsLoading(true);
          ApiFetch(
            (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
            `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
            `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/active`,  // page:0 y pageSize: -1 === all registers
            globalContext,
            true,
            async (body) => {
              let resJson = await body.json()
              let auxRowsIds = resJson.res.map((o) => o.id)
              setSelectedRows(auxRowsIds);
              setIsLoading(false);
            },
            error => {
              setIsLoading(false);
            },
            {
              method: "POST",
              body: JSON.stringify({ 
                'filters': filterValues, 
                'outputFields': ['id'],
                'eventType': props.event?.eventType,
                'boxType': selectedBox.subtype
              }),
            }
          );
          break;
        case 'none':
          setSelectedRows([])
          break;
        case 'invert':
          setIsLoading(true);
          ApiFetch(
            (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
            `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
            `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/active`,  // page:0 y pageSize: -1 === all registers
            globalContext,
            true,
            async (body) => {
              let resJson = await body.json()
              let auxRowsIds = resJson.res.map((o) => o.id)
              setSelectedRows(auxRowsIds.filter((a) => !selectedRows.includes(a)));
              setIsLoading(false);
            },
            error => {
              setIsLoading(false);
            },
            {
              method: "POST",
              body: JSON.stringify({ 
                'filters': filterValues, 'outputFields': ['id'],
                'eventType': props.event?.eventType,
                'boxType': selectedBox.subtype
              }),
            }
          );
          break;
        case 'some':
          setIsLoading(true);
          ApiFetch(
            (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType)) ?
            `${props.url}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/transferred/${selectedBox.id}/${props.event?.eventType}` :
            `${props.url}/0/${someValue}/${sortModel[0].field}/${sortModel[0].sort}/active`,  // page:0 y pageSize: -1 === all registers
            globalContext,
            true,
            async (body) => {
              let resJson = await body.json()
              let auxRowsIds = resJson.res.map((o) => o.id)
              setSelectedRows(auxRowsIds);
              setIsLoading(false);
            },
            error => {
              setIsLoading(false);
            },
            {
              method: "POST",
              body: JSON.stringify({ 
                'filters': filterValues, 'outputFields': ['id'],
                'eventType': props.event?.eventType,
                'boxType': selectedBox.subtype
              }),
            }
          );
          break;
        default:
          break;
      }
    } else {
      let auxSelectedRows = [...selectedRows]
      let index = auxSelectedRows.findIndex((v) => v === value)
      index === -1 ? auxSelectedRows.push(value) : auxSelectedRows.splice(index, 1)
      setSelectedRows(auxSelectedRows)

    }
  }

  function CustomNoRowsOverlay() {
    return (
      <div className="norows" style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px'
      }}
      >
        {filterValues === 0 ? t("largeGrid.msg.noDataLevel") : t("largeGrid.msg.noDataFilter")}
      </div>
    );
  }

  const handleToggleColumnsExport = (index) => () => {
    const newCols = [...columnsExport]
    newCols[index].active = !newCols[index].active
    setColumnsExport(newCols)
  };

  const excelStructureBuilder = (allRows,columnsExport) => {

    return allRows.map(row =>
      Object.fromEntries(columnsExport
        .filter(column => column.active)
        .map(({ headerName, field, fieldType }) =>
          [headerName, row[field+'_hashed_url'] 
            ? JSON.parse( row[field+'_hashed_url'] ) 
            : (fieldType === "date"
              ? dateSanitizer(row[field])
              : row[field])]
      )))
  }

  return (
    <div style={{ height: "auto", width: '100%' }}>

      <PopoverErrorMesssage
        errorId={null}
        errorOpen={errorOpen}
        errorCell={errorCell}
        closeError={closeError}
        error={[t("largeGrid.msg.paused")]}
      />

      <BasicDialog
        open={open}
        setOpen={setOpen}
        actionButton={
          <>
            <ExcelExport
              data={ excelStructureBuilder(allRows,columnsExport) }
              fileName={'tabla'}
              onClick={() => setOpen(false)}
            />
            {exportLoading && <Loading height={20} size={15}/>}
          </>
        }>
        <Typography>Columnas que se exportan: </Typography>
        <List>
          {columnsExport?.map((value, index) => {
            const labelId = `checkbox-list-label-${value.field}`;
            return (
              <ListItem
                key={value.field}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleToggleColumnsExport(index)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={value.active}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.headerName} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </BasicDialog>

      <BasicFilter
        entity={props.entity}
        fields={props.fields}
        filterValues={filterValues}
        columns={columns.filter(c => c.headerName.length > 0 && !c.hide)}
        setFilterValues={setFilterValues}
        setFilterValuesLoaded={setFilterValuesLoaded}
        sessionContext={props.sessionContext}
        urlBoxesLots={
          (props.statePath === 'transferredAssets' || models?.eventInAssetTransferred?.includes(props.event?.eventType))?
          `boxes/${selectedBox.id}/lots_transferred/${selectedAsset.id}/${props.event?.eventType}` :
          `boxes/${selectedBox.id}/lots`
        }
      />

      {
        (props.show && isInit) &&
        <>
          <Grid container sx={{ p: 2, mb: 4, mt: 2, alignItems: 'center' }} style={{ margin: '16px', padding: 0 }}>
            <Grid item sx={{ pr: 2 }} xs={6} >
              <div style={{ paddingLeft: '10px', color: '#6d6d6d', fontSize: '0.9rem' }}>{t("largeGrid.title")}{selectedRows.length}</div>
            </Grid>
            <Grid item sx={{ pr: 2 }} xs={6} style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button variant={'outlined'}
                onClick={() => {
                  setOpen(true)
                  loadAllFilteredRows()
                }}
                sx={{ mr: 1 }}>
                {t("largeGrid.button.export")}
              </Button>
              <Button variant={'outlined'}
                onClick={async () => {
                  await loadServerRows(rowsState.page, rowsState.pageSize, sortModel[0]);
                }}
                sx={{ mr: 1 }}>{t("largeGrid.button.reload")}
              </Button>
              <SplitButton
                variant={'outlined'}
                options={[
                  { label: t("largeGrid.button.select.none"), key: 'none', withValue: false },
                  { label: t("largeGrid.button.select.all"), key: 'all', withValue: false },
                  { label: t("largeGrid.button.select.invert"), key: 'invert', withValue: false },
                  { label: t("largeGrid.button.select.some"), key: 'some', withValue: true }]}
                handleClick={(e, v) => { setSelectedOption(e); setSomeValue(v) }}
              />
            </Grid>
          </Grid>
          <DataGrid
            className="data-grid-custom-pagination"
            sx={{
              height: '23rem',
              overflowY: 'auto'
            }}
            autoHeight={false}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            onRowClick={(row) => { props.onSelected(row) }}
            disableColumnMenu
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            hideFooterSelectedRowCount={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            componentsProps={{
              pagination: { labelRowsPerPage: t("largeGrid.pagination") },
              toolbar: {
                csvOptions: { fields: columnsExport.filter((col) => col.active === true).map((col) => col.field) },
                printOptions: { disableToolbarButton: true }
              }
            }}
            pageSize={pageSizeDGs}
            rowsPerPageOptions={constPaginationDataGrid.defaultPageSizesArray}
            onPageSizeChange={(newPageSize) => {
              setPageSizeDGs(newPageSize)
              setRowsState((prev) => ({ ...prev, pageSize: newPageSize }))}
            }
          />
          {props.exportFilterDataConfig?.mode &&
            <Grid item sx={{ pr: 4 }} xs={12} style={{ display: 'flex', width: '100%', justifyContent: 'center' }} >
              <Button disabled={selectedRows.length === 0}
                style={{ marginTop: "20px", marginBottom: "20px" }}
                className="ml-3" variant="contained"
                onClick={() => { loadServerRowsWithoutPaginator(); }}>
                {props.exportFilterDataConfig?.caption}
              </Button>
            </Grid>
          }
        </>
      }
    </div >
  )
}

export default LargeGridData;
