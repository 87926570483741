import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'right',
    marginRight: theme.spacing(3),
  },
}));

const Version = () => {
  const classes = useStyles();
  const versionNumber = 'v2.7.2';

  return (
    <Box className={classes.root}>
      <p>{versionNumber}</p>
    </Box>
  );
};

export default Version;
