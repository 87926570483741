import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { ApiFetch, SyncApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import useThemeContext from '../../../Hooks/useThemeContext';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import {
  StyledButton,
  StyledAccordion,
  StyledAccordionSummary,
} from './SidebarTop.styles';
import {
  Box,
  Card,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faIndustry,
  faTruck,
  faBuilding,
  faDiceD8,
} from '@fortawesome/pro-light-svg-icons';
import {
  COMPANY_ALL
} from '../../../constants/paths';
import { getSupplyChainFromSubdomain } from '../../../Helpers/subdomain';

const TopItems = [
  {
    id: 1,
    name: 'Cadena de suministro',
    icon: faTruck,
  },
  {
    id: 2,
    name: 'Activo',
    icon: faDiceD8,
  },
  {
    id: 3,
    name: 'Empresa',
    icon: faIndustry,
  },
  {
    id: 4,
    name: 'Establecimiento',
    icon: faBuilding,
  },
];

function SidebarTopComponent(props) {
  const { t } = useTranslation()
  const history = useHistory();
  const { swithMenuMode, setActiveThemeFromAsset } = useThemeContext();
  const globalContext = useGlobalContext();
  const [expanded, setExpanded] = React.useState(0);
  const [chains, setChains] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [actives, setActives] = React.useState([]);
  const [establishment, setEstablishment] = React.useState([]);
  const [selectedAsset, setSelectedAsset] = React.useState({ id: -1 })
  const [selectedBox, setSelectedBox] = React.useState({
    id: -1,
    name: "",
  })
  const [selectedChain, setSelectedChain] = React.useState({ id: -1, name: "" })
  const [selectedCompany, setSelectedCompany] = React.useState({ id: -1, name: "" })
  const [selectData, setSelectData] = React.useState(
    props.data != null
      ?
      props.data
      :
      {
        chain: { id: -1, name: '' },
        active: { id: -1, name: '' },
        company: { id: -1, name: '' },
        establishment: { id: -1, name: '', subtype: -1, nav: {} },
      }
  );
  const currentSupplyChain = getSupplyChainFromSubdomain();

  const saveTopBar = async () => { }


  useEffect(() => {
    setActiveThemeFromAsset(selectedAsset.id)
  }, [selectedAsset]);

  useEffect(() => {
    if (chains.length === 1) {
      let itemChain = chains[0];
      HandleOnClickChain(itemChain.id, itemChain.value, 0, null)
    }
  }, [chains]);

  const setPrehomeMode = () => {
    swithMenuMode('PREHOME');
    history.push(COMPANY_ALL)
  }

  useEffect(async () => {
    bringChainData();
    try {
      let tempChains = await SyncApiFetch('topBar/owner/chains', globalContext, true);
      if (tempChains.length === 1) {
        let tempActives = await SyncApiFetch('topBar/owner/actives/' + tempChains[0].id + '/chain', globalContext, true);
        if (tempActives.length === 1) {
          let tempCompanies = await SyncApiFetch('topBar/companies/' + tempActives[0].id + '/actives', globalContext, true);
          if (tempCompanies.length === 1) {
            let tempEstablishments = await SyncApiFetch('topBar/boxes/' + tempCompanies[0].id + '/establishment', globalContext, true);
            if (tempEstablishments.length === 1) {
              let topBar = {
                chain: { id: tempChains[0].id, name: tempChains[0].value },
                establishment: {
                  id: tempEstablishments[0].id,
                  name: tempEstablishments[0].value,
                  subtype: tempEstablishments[0].subtype,
                  nav: [
                    {
                      id: tempEstablishments[0].id,
                      name: tempEstablishments[0].value,
                      subtype: tempEstablishments[0].subtype
                    }
                  ],
                },
                active: { id: tempActives[0].id, name: tempActives[0].value },
                company: { id: tempCompanies[0].id, name: tempCompanies[0].value },
              };
              setTimeout(() => {
                saveSelectedData(topBar);
              }, 10);
            }
          }
        }
      }
    } catch (e) {

    }


  }, []);

  useEffect(() => {
    let topBar = {
      chain: { id: selectedChain.id, name: selectedChain.name },
      establishment: {
        id: selectedBox.id,
        name: selectedBox.name,
        subtype: selectedBox.subtype,
        nav: selectedBox.nav,
      },
      active: { id: selectedAsset.id, name: selectedAsset.name },
      company: { id: selectedCompany.id, name: selectedCompany.name },
    };

    if (
      topBar.chain.id !== -1 &&
      topBar.establishment.id !== -1 &&
      topBar.active.id !== -1 &&
      topBar.company.id !== -1
    ) {
      setSelectData(topBar);
      setExpanded(-1);
    } else {
      setExpanded(expanded);
    }
  }, [selectedChain, selectedBox, selectedAsset, selectedCompany]);

  useEffect(() => {
    getSelectedData();
    if (props.handleBoxSelected) {
      props.handleBoxSelected(selectData);
    }
  }, [selectData]);

  const bringChainData = () => {
    ApiFetch(
      'topBar/owner/chains',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let ret = await res.json();
          const filteredChains = currentSupplyChain
          ? ret.filter(chain => chain.id === currentSupplyChain)
          : ret;
          setChains([...filteredChains]);
        }
      },
      (error) => {
        console.log('error topBar/owner/chains', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const bringActivesData = (chainId) => {
    if (chainId === -1) {
      setActives([]);
    } else {
      ApiFetch(
        'topBar/owner/actives/' + chainId + '/chain',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let actives = await res.json();
            setActives(actives);
          }
        },
        (error) => {
          console.log('error topBar/owner/actives', error);
        },
        {
          method: 'GET',
        }
      );
    }
  };

  const bringCompaniesData = (activeId) => {
    if (activeId === -1) {
      setCompanies([]);
    } else {
      ApiFetch(
        'topBar/companies/' + activeId + '/actives',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let companies = await res.json();
            setCompanies(companies);
          }
        },
        (error) => {
          console.log('error topBar/owner/companies', error);
        },
        {
          method: 'GET',
        }
      );
    }
  };

  const bringEstablishmentData = (companyId) => {
    if (companyId === -1) {
      setEstablishment([]);
    } else {
      ApiFetch(
        'topBar/boxes/' + companyId + '/establishment',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let data = await res.json();
            setEstablishment(data);
          }
        },
        (error) => {
          console.log('debug 01', error);
        },
        {
          method: 'GET',
        }
      );
    }
  };

  const HandleOnClickChain = (id, name, index, event) => {
    let data = selectData;
    data.chain.id = id;
    data.chain.name = name;
    data.company.id = -1;
    data.company.name = '';
    setCompanies([]);
    data.active.id = -1;
    data.active.name = '';
    setActives([]);
    data.establishment.id = -1;
    data.establishment.name = '';
    setEstablishment([]);

    saveSelectedData(data);

    setExpanded(index + 1);

    bringActivesData(data.chain.id);

    setSelectedChain(data.chain);
  };

  const HandleOnClickActives = (id, name, index) => {
    let data = selectData;
    data.active.id = id;
    data.active.name = name;
    data.company.id = -1;
    data.company.name = '';
    setCompanies([]);
    data.establishment.id = -1;
    data.establishment.name = '';
    setEstablishment([]);

    saveSelectedData(data);
    setExpanded(index + 1);

    bringCompaniesData(id);

    setSelectedAsset(data.active);
  };

  const HandleOnClickCompanies = (id, name, index) => {
    let data = selectData;
    data.company.id = id;
    data.company.name = name;

    data.establishment.id = -1;
    data.establishment.name = '';
    setEstablishment([]);

    saveSelectedData(data);
    setExpanded(index + 1);

    bringEstablishmentData(id);

    setSelectedCompany(data.company);
  };

  const HandleOnClickEstablishment = (id, name,) => {
    let data = selectData;
    data.establishment.id = id;
    data.establishment.name = name;
    
    saveSelectedData(data);

    setSelectedBox(data.establishment);
  };

  const saveSelectedData = async (data) => {
    setSelectData({ ...data });
    saveTopBar({ ...data });
  };

  const getSelectedData = async () => {
    let data = selectData;
    if (data !== '') {
      setSelectData(data);

      let selectAccordion = 1;
      if (data.chain.id !== -1) bringActivesData(data.chain.id);
      if (data.active.id !== -1) {
        bringCompaniesData(data.active.id);
        selectAccordion = 2;
      }
      if (data.company.id !== -1 && data.company.id !== -1) {
        bringEstablishmentData(data.company.id);
        selectAccordion = 3;
      }

      if (data.establishment.id !== -1) {
        selectAccordion = 4; // select out
      }

      setExpanded(selectAccordion);
    }
  };

  const getAccordionName = (name, index) => {
    switch (index) {
      case 1:
        if (selectData.chain.name !== '') return selectData.chain.name;
        break;
      case 2:
        if (selectData.active.name !== '') return selectData.active.name;
        break;
      case 3:
        if (selectData.company.name !== '') return selectData.company.name;
        break;
      case 4:
        if (selectData.establishment.name !== '') return selectData.establishment.name;
        break;
      default: return ''
    }
    return name;
  };

  const changeOnClick = (index, e) => {
    // si click cuand oesta cerrado
    if (e.target.nodeName === 'svg' || e.target.nodeName === 'P' || e.target.nodeName === 'DIV') {
      if (expanded === index) {
        setExpanded(-1);
      } else {
        setExpanded(index);
      }
    } else if (e.target.nodeName === 'SPAN' && expanded === 4) setExpanded(index + 1); // si click cuand oesta abierto
  };

  const sxTopBar = {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    boxShadow: '0px 4px 35px rgba(109, 102, 119, 0.2)',
    border: 'none',
    borderRadius: '10px',
    position: 'absolute',
    zIndex: 1,
    width: 'calc(100% - 32px)',
    boxSizing: 'unset',
  }

  const sxComponent = {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    boxShadow: '0px 4px 35px rgba(109, 102, 119, 0.2)',
    border: 'none',
    borderRadius: '10px',
    //position: 'absolute',
    zIndex: 1,
    width: 'calc(100% - 32px)',
    boxSizing: 'unset',
  }

  return (
    <>

      <Box
        component={Card}
        variant="outlined"
        sx={props.topBarMode ? sxTopBar : sxComponent}>
        {props.topBarMode &&
          <StyledButton variant="contained" onClick={() => { setPrehomeMode() }}>{t('menu.admin')}</StyledButton>
        }
        {TopItems.map((item, index) => (
          <StyledAccordion
            key={index}
            sx={{ marginTop: 1 }}
            expanded={expanded === index}
            onClick={(event) => changeOnClick(index, event)}>
            <StyledAccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              aria-controls={index}
              id={index}
            >
              <FontAwesomeIcon icon={item.icon} />
              &nbsp;&nbsp;&nbsp;
              {getAccordionName(item.name, item.id) === item.name && <div>{item.name}</div>}
              {getAccordionName(item.name, item.id) !== item.name && (
                <Typography style={{ fontWeight: '500' }}>
                  {getAccordionName(item.name, item.id)}
                </Typography>
              )}
            </StyledAccordionSummary>

            {item.id === 1 &&
              chains.map((itemChain) => (
                <AccordionDetails sx={{ p: 0 }}>
                  <List dense>
                    <ListItem disablePadding key={'1' + index}>
                      <ListItemButton
                        onClick={(event) =>
                          HandleOnClickChain(itemChain.id, itemChain.value, index, event)
                        }
                        id={itemChain.id}>
                        <ListItemText primary={itemChain.value} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              ))}
            {item.id === 2 &&
              actives.map((itemActive) => (
                <AccordionDetails sx={{ p: 0 }}>
                  <List dense>
                    <ListItem disablePadding key={'2' + index}>
                      <ListItemButton
                        onClick={() => HandleOnClickActives(itemActive.id, itemActive.value, index)}
                        id={itemActive.id}>
                        <ListItemText primary={itemActive.value} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              ))}

            {item.id === 3 &&
              companies.map((itemCompany) => (
                <AccordionDetails sx={{ p: 0 }}>
                  <List dense>
                    <ListItem disablePadding key={'3' + index}>
                      <ListItemButton
                        onClick={() =>
                          HandleOnClickCompanies(itemCompany.id, itemCompany.value, index)
                        }
                        id={itemCompany.id}>
                        <ListItemText primary={itemCompany.value} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </AccordionDetails>
              ))}
            {item.id === 4 &&
              establishment.map((itemEstablish, index) => (
                <AccordionDetails sx={{ p: 0 }}>
                <List dense>
                  <ListItem disablePadding key={'4' + index}>
                    <ListItemButton
                      onClick={() =>
                        HandleOnClickEstablishment(itemEstablish.id, itemEstablish.value)
                      }
                      id={itemEstablish.id}>
                      <ListItemText primary={itemEstablish.value} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </AccordionDetails>
              ))}
          </StyledAccordion>
        ))}
      </Box>

    </>
  );
}

export default SidebarTopComponent;
