import { subdomainToSupplyChainDict } from "../constants";

export const getSubdomain = () => {
    const host = window.location.hostname;
    return host.split(".")[0];
};

export const getSupplyChainFromSubdomain = () => {
    const subdomain = getSubdomain();
    return subdomainToSupplyChainDict[subdomain] || null;
};
  