import { createContext, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import {
  ApiFetch,
  SyncApiFetch,
  DBSessionDataApiFetch,
} from '../Utils/ApiFetch';
import { getActualQuotaForCompany } from '../services/billing';
import { DEFAULT_LANGUAGES } from '../constants';
import { signOut } from '../services/user';
import { getInitialLanguage } from '../Helpers/translation';
import { ThemeContext } from './themeContext';
import { constPaginationDataGrid } from '../constants/paginationDataGrid';

const AUTHENTICATED = 'AUTHENTICATED';
const USER_TOKEN = 'USER_TOKEN';
const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
const USER_DATA = 'USER_DATA';
const SESSION_DATA = 'SESSION_DATA';

export const GlobalContext = createContext();

export default function GlobalContextProvider({ children }) {
  const initiatedItemsScope = ['models'];

  const [uniqueValidationCache, setUniqueValidationCache] = useState({});

  const [selectedChain, setSelectedChain] = useState({ id: -1 });
  const [selectedAsset, setSelectedAsset] = useState({ id: -1 });
  const [selectedCompany, setSelectedCompany] = useState({ id: -1 });
  const [selectedBox, setSelectedBox] = useState({ id: -1 });
  const [relationUserEntity, setRelationUserEntity] = useState("");


  const [selectedCompanyAdmin, setSelectedCompanyAdmin] = useState({
    id: -1,
    name: '',
  });

  const [headerTitle, setHeaderTitle] = useState(null);
  const [headerSubtitle, setHeaderSubtitle] = useState(null);

  const [selectedRowGridMassiveEdit, setSelectedRowGridMassiveEdit] =
    useState(null);

  const { themes } = useContext(ThemeContext);
  const [activeTheme, setActiveTheme] = useState(themes?.greenTheme || themes?.defaultGreenTheme);
  const [menuMode, setMenuMode] = useState('HOME');

  let debug001 = {};
  const addUniqueValidationCache = (key, val) => {
    debug001.key = val;
  };

  const [sessionData, setSessionData] = useState(() => {
    if (localStorage.getItem(SESSION_DATA)) {
      return JSON.parse(localStorage.getItem(SESSION_DATA));
    }
    return {};
  });

  const [initiatedItemsComplete, setInitiatedItemsComplete] = useState(false);
  const [initiatedItems, setInitiatedItems] = useState([]);

  const [userData, setUserData] = useState(() => {
    if (localStorage.getItem(USER_DATA)) {
      return JSON.parse(localStorage.getItem(USER_DATA));
    }
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    if (localStorage.getItem(AUTHENTICATED)) {
      return localStorage.getItem(AUTHENTICATED);
    }
  });

  const [mandatoryFieldsErrorStack, setMandatoryFieldsErrorStack] = useState([]);

  const [userToken, setUserToken] = useState(() => {
    if (localStorage.getItem(USER_TOKEN)) {
      return localStorage.getItem(USER_TOKEN);
    }
  });

  const [refreshToken, setRefreshToken] = useState(() => {
    if (localStorage.getItem(USER_REFRESH_TOKEN)) {
      return localStorage.getItem(USER_REFRESH_TOKEN);
    }
  });

  const [isUserLoggedBind, setIsUserLoggedBind] = useState(false);

  const [dbSessionData, setDBSessionData] = useState(null);

  const [pageSizeDGs, setPageSizeDGs] = useState(constPaginationDataGrid.defaultPageSize);

  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(getInitialLanguage());
  const getLanguagesFromStorage = () => {
    try {
      const storedLanguages = localStorage.getItem('languagesAvailables');
      return storedLanguages ? JSON.parse(storedLanguages) : DEFAULT_LANGUAGES;
    } catch (error) {
      console.error("Error al parsear JSON desde localStorage:", error);
      return DEFAULT_LANGUAGES;
    }
  };  
  const [languages, setLanguages] = useState(getLanguagesFromStorage());

  const addInitiatedItem = (item) => { 
    let initiatedItemsTemp = [...initiatedItems];
    initiatedItemsTemp.push(item);
    setInitiatedItems(initiatedItemsTemp);
  };

  const onChangeLanguage = (
    languagesAvailables,
    currentLanguage
  ) => {
    setLanguages(languagesAvailables);
    setSelectedLang(currentLanguage);

    localStorage.setItem('languagesAvailables', JSON.stringify(languagesAvailables));
    localStorage.setItem('selectedLanguage', currentLanguage);
    i18n.changeLanguage(currentLanguage);
  }

  const changeLanguageBasedInSupplychain = () => {
    const currentModel = sessionData['models'][selectedAsset.id];
    const languageParts = selectedLang.split("_");
    const languagePart = languageParts[languageParts.length > 1 ? 1 : 0];
    if (currentModel?.header?.languages) {
      const defaultLanguageParts = currentModel.header.languages.default.split("_");
      const defaultLanguagePart = defaultLanguageParts.length > 1 ? (defaultLanguageParts[0] + "_" + languagePart) : languagePart;
      onChangeLanguage(
        currentModel.header.languages.values,
        defaultLanguagePart
      );
    }
    else {
      onChangeLanguage(DEFAULT_LANGUAGES, languagePart);
    }
  }

  const handleChangeLanguage = (event) => {
    const newLang = event.target.value;
    setSelectedLang(newLang);
    onChangeLanguage(languages, newLang);
  };

  useEffect(() => {
    if (selectedAsset?.id > 0 && sessionData?.models) {
      changeLanguageBasedInSupplychain();
    }
  }, [selectedAsset?.id, sessionData?.models]);

  useEffect(() => {
    if (menuMode === "PREHOME") {
      setActiveTheme(themes?.blueTheme);
    } else if (menuMode === "HOME") {
      setActiveTheme(themes?.greenTheme);
    }
  }, [menuMode, themes]);

  useEffect(() => {
    let complete = true;
    for (let itemScope of initiatedItemsScope) {
      if (
        initiatedItems.includes(itemScope) === false ||
        getSessionDataByKey(itemScope) === undefined
      ) {
        complete = false;
      }
    }

    setInitiatedItemsComplete(complete);
  }, [initiatedItems, sessionData]);

  useEffect(() => {
    if (isAuthenticated && userData != null) {
      setIsUserLoggedBind(true);
    }
  }, [userData]);

  const updateSessionData = (updateSessionData) => {
    //TODO: verificar que el Json que viene tenga un solo nivel y esté bien formado
    for (var newKey in updateSessionData) {
      for (var oldKey in sessionData) {
        if (newKey === oldKey) {
          sessionData[oldKey] = updateSessionData[newKey];
          delete updateSessionData[newKey];
        }
      }
    }
    for (var newKey in updateSessionData) {
      sessionData[newKey] = updateSessionData[newKey];
    }
    localStorage.setItem(SESSION_DATA, JSON.stringify(sessionData));
    setSessionData(sessionData);
  };

  const getSessionDataByKey = (key) => {
    if (sessionData == null) {
      return null;
    }
    return sessionData[key];
  };

  /*
   * DB Session data
   */

  useEffect(async () => {
    const tokenTemp = await bindValidToken();

    DBSessionDataApiFetch(
      'users/getPreferences',
      tokenTemp,
      true,
      async (body) => {
        const response = await body.json();
        setDBSessionData(response.userPreferences);
      },
      (error) => {
        // console.log(error);
      }
    );
  }, [userToken]);

  const updateDBSessionData = async (updateDBSessionData) => {
    //TODO: verificar que el Json que viene tenga un solo nivel y esté bien formado
    if (dbSessionData === null) {
      const tokenTemp = await bindValidToken();
      DBSessionDataApiFetch(
        'users/savePreferences',
        tokenTemp,
        true,
        async (body) => {
          const response = await body.json();
          return response;
        },
        (error) => {
          // console.log(error);
        },
        {
          method: 'POST',
          body: JSON.stringify(updateDBSessionData),
        }
      );
      setDBSessionData(updateDBSessionData);
    } else {
      for (var newKey in updateDBSessionData) {
        for (var oldKey in dbSessionData) {
          if (newKey === oldKey) {
            dbSessionData[oldKey] = updateDBSessionData[newKey];
            delete updateDBSessionData[newKey];
          }
        }
      }
      for (var newKey in updateDBSessionData) {
        dbSessionData[newKey] = updateDBSessionData[newKey];
      }
      const tokenTemp = await bindValidToken();
      DBSessionDataApiFetch(
        'users/savePreferences',
        tokenTemp,
        true,
        async (body) => {
          const response = await body.json();
          return response;
        },
        (error) => {
          // console.log(error);
        },
        {
          method: 'POST',
          body: JSON.stringify(dbSessionData),
        }
      );
    }
  };

  async function getDBSessionDataByKey(key) {
    const tokenTemp = await bindValidToken();
    return new Promise((resolve, reject) => {
      DBSessionDataApiFetch(
        'users/getPreferences/',
        tokenTemp,
        true,
        (body) => {
          const response = async () => {
            const jsonResponse = await body.json();
            setDBSessionData(jsonResponse.userPreferences);
            if (jsonResponse.userPreferences == null) return '';
            return jsonResponse.userPreferences[key];
          };
          resolve(response());
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  async function getDBSessionData() {
    const tokenTemp = await bindValidToken();
    return new Promise((resolve, reject) => {
      DBSessionDataApiFetch(
        'users/getPreferences',
        tokenTemp,
        true,
        (body) => {
          const response = async () => {
            const jsonResponse = await body.json();
            setDBSessionData(jsonResponse.userPreferences);
            return jsonResponse.userPreferences;
          };
          resolve(response());
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  async function refreshUserData() {
    const tokenTemp = await bindValidToken();
    return new Promise((resolve, reject) => {
      DBSessionDataApiFetch(
        'users/getInfo',
        tokenTemp,
        true,
        (body) => {
          const response = async () => {
            const jsonResponse = await body.json();
            localStorage.setItem(USER_DATA, JSON.stringify(jsonResponse));
            setUserData(jsonResponse);
          };
          resolve(response());
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  const login = (token, user, refreshToken) => {
    localStorage.setItem(AUTHENTICATED, true);
    setIsAuthenticated(true);
    localStorage.setItem(USER_TOKEN, token);
    setUserToken(token);
    localStorage.setItem(USER_REFRESH_TOKEN, refreshToken);
    setRefreshToken(refreshToken);
    localStorage.setItem(USER_DATA, JSON.stringify(user));
    setUserData(user);
    localStorage.setItem(SESSION_DATA, JSON.stringify({}));
    setSessionData({});
  };

  const logout = () => {
    signOut().then(() => {
      localStorage.removeItem(AUTHENTICATED);
      setIsAuthenticated(false);
      localStorage.removeItem(USER_TOKEN);
      setUserToken(null);
      localStorage.removeItem(USER_REFRESH_TOKEN);
      setRefreshToken(null);
      localStorage.removeItem(USER_DATA);
      setUserData(null);
      localStorage.removeItem(SESSION_DATA);
      setSessionData({});
      setIsUserLoggedBind(false);
    }).catch(err => console.log('error signOut', err))
  };

  function getNewToken() {
    return new Promise((resolve, reject) => {
      ApiFetch(
        'users/newToken',
        this,
        false,
        (body) => {
          const token = async () => {
            const jsonResponse = await body.json();
            return jsonResponse.token;
          };
          resolve(token());
        },
        (error) => {
          resolve(null);
        },
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: refreshToken,
          }),
        }
      );
    });
  }

  function getNewTokenSync() {
    const response = SyncApiFetch('users/newToken', this, false);
    return response;
  }

  const bindValidToken = async (ref) => {
    const token = localStorage.getItem(USER_TOKEN);
    if (token) {
      const decodedToken = jwt_decode(token);
      //console.log("isValid A", ref, decodedToken.exp - Math.floor(Date.now() / 1000));
      if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
        let newToken = await getNewToken();
        if (newToken != null) {
          localStorage.setItem(USER_TOKEN, newToken);
          setUserToken(newToken);
          return newToken;
        } else {
          logout();
          return null;
        }
      } else {
        return token;
      }
    } else {
      return null;
    }
  };
  const bindValidTokenSync = () => {
    const token = localStorage.getItem(USER_TOKEN);
    if (token) {
      const decodedToken = jwt_decode(token);
      //console.log("isValid", decodedToken.exp - Math.floor(Date.now() / 1000));
      if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
        let newToken = getNewTokenSync();
        if (newToken != null) {
          localStorage.setItem(USER_TOKEN, newToken);
          setUserToken(newToken);
          return newToken;
        } else {
          logout();
          return null;
        }
      } else {
        return token;
      }
    } else {
      return null;
    }
  };

  const initTopBar = async () => {
    let data = await getDBSessionDataByKey('topBar');
    if (data === '') return;

    setSelectedChain({ ...data.chain });

    if (data.active) {
      if (data.active.id !== selectedAsset.id) {
        setSelectedAsset({ ...data.active });
      }
    }

    setSelectedCompany({ ...data.company });
    setSelectedBox({ ...data.establishment });
    setRelationUserEntity(data.relationEntityUser)
  };

  const saveTopBar = async (data) => {
    setSelectedChain(data.chain);
    setSelectedAsset(data.active);
    setSelectedCompany(data.company);
    setSelectedBox(data.establishment);
    setRelationUserEntity(data.relationEntityUser);

    await updateDBSessionData({ topBar: data });
  };

  const swithMenuMode = (mode) => {
    setMenuMode(mode);
  };
  const saveCompany = async (company) => {
    let data = await getDBSessionDataByKey('topBar');
    setSelectedCompany(company);

    data = { ...data, company: company };
    await updateDBSessionData({ topBar: data });
  };

  const calculateActualQuota = async (quotaProps) => {
    const token = await bindValidToken();
    const {
      data: { canUse, remainingQuota, companiesOwnerLength },
    } = await getActualQuotaForCompany(quotaProps, token);
    return { canUse, remainingQuota, companiesOwnerLength };
  };

  const value = {
    login,
    logout,
    bindValidToken,
    bindValidTokenSync,
    updateSessionData,
    getSessionDataByKey,
    updateDBSessionData,
    getDBSessionDataByKey,
    getDBSessionData,
    isAuthenticated,
    userData,
    userToken,
    sessionData,
    addInitiatedItem,
    initiatedItemsComplete,
    selectedChain,
    selectedAsset,
    selectedCompany,
    selectedCompanyAdmin,
    setSelectedCompanyAdmin,
    selectedBox,
    relationUserEntity,
    saveCompany,
    initTopBar,
    saveTopBar,
    refreshUserData,
    activeTheme,
    setActiveTheme,
    menuMode,
    swithMenuMode,
    isUserLoggedBind,
    headerTitle,
    headerSubtitle,
    setHeaderTitle,
    setHeaderSubtitle,
    uniqueValidationCache,
    setUniqueValidationCache,
    addUniqueValidationCache,
    setSelectedRowGridMassiveEdit,
    selectedRowGridMassiveEdit,
    calculateActualQuota,
    selectedLang,
    setSelectedLang,
    languages,
    i18n,
    t,
    onChangeLanguage,
    changeLanguageBasedInSupplychain,
    handleChangeLanguage,
    mandatoryFieldsErrorStack,
    setMandatoryFieldsErrorStack,
    pageSizeDGs,
    setPageSizeDGs
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

GlobalContextProvider.propTypes = {
  children: PropTypes.object,
};
