import React, { useEffect, useState } from 'react'
import { QrTemplate } from '../components'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getQrIdenteco, postQrIdenteco } from '../../../services/qr';
import { useTranslation } from 'react-i18next';

export const QrIdenteco = () => {
  const { id } = useParams();
  const [attributes, setAttributes] = useState()
  const { t } = useTranslation();
  const [qrModel, setQrModel] = useState(null)
  const [errorMessage, setErrorMessage] = useState({
    flag: false,
    message: ''
  })

  useEffect(() => {
    getQrIdenteco(id)
      .then(res =>{ 
        if (res.status === 206) {
          setQrModel(res.data.model ? res.data.model : null)
          return setErrorMessage({
            flag: true,
            message: res.data.message ? res.data.message : res.data
          })
        } else { setAttributes(res.data) }
      })
      .catch(err => {
        console.log('Error getQrIdenteco', err)
        setErrorMessage({
          flag: true,
          message: ''
        })
      })
    postQrIdenteco(id)
  }, [id]);

  return (
    <QrTemplate
      attributes={attributes}
      editMode={false}
      errorMessage={ errorMessage.flag ? 
        (errorMessage.message ? t(`template.error.${errorMessage.message}`) : t('template.error.default')) : ''
      }
      flagApology={errorMessage.message ? false : true}
      qrErrorImg={qrModel ? `/images/qrErrors/errorImg${qrModel}.png` : null}
    />
  )
}
