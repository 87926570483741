import React, { useEffect, useState } from 'react';
import { Grid, Stack, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';
import { EVENTS_MASSIVE } from '../../constants/paths';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getBoxActivityEvent } from '../../services/boxes';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MassiveAsyncModalAccept from '../../Components/Modal/MassiveAsyncModalAccept';
import { dateSanitizer } from '../../Helpers/dateFormater';
import { constPaginationDataGrid } from '../../constants/paginationDataGrid';

const cols = [
  { field: 'id', headerName: 'Id del evento' },
  { field: 'event_type', headerName: 'Tipo de Evento' },
  { field: 'event_assets_count', headerName: 'Assets involucrados' },
  { field: 'timsta', headerName: 'Fecha', type: 'dateTime', width:'200' },
  { field: 'username', headerName: 'Usuario' },
]

function EventsMain(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { userData, selectedBox, selectedAsset, pageSizeDGs, setPageSizeDGs } =
    useGlobalContext();
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [asyncModal, setAsyncModal] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getBoxActivityEvent(selectedBox.id)
      .then(res => {
        let data = res.data.map(a => { 
          a.timsta = dateSanitizer(a.timsta)
          return a
          });
        setData(data);
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [selectedBox.id])

  useEffect(() => {
    if (props.location?.state?.toast) {
      let msg = '';
      if (
        props.location.state.toast.action === 'updated_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.update');
      }
      if (
        props.location.state.toast.action === 'wait_for_massive' &&
        props.location.state.toast.mode === 'update'
      ) {
        msg = t('breadcrumb.asset.msg.wait_for_massive_update');
      }
      if (msg !== '') {
        toast.success(msg, {
          className: 'toast-message-body',
          progressClassName: 'toast-message-progress',
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [props.location?.state?.toast]);

  useEffect( ()=>{
    setAsyncModal(props.location.state?.modal?.asyncModal)
  },[props.location.state?.modal?.asyncModal] )

  const redirectToMassiveEvents = () =>  {
      history.push({
      pathname: EVENTS_MASSIVE, 
      state: { source: 'events' }, 
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              disabled={!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_EDIT") === true)}
              onClick={redirectToMassiveEvents}
              startIcon={<FontAwesomeIcon icon={faPlus} />}>
              {t("breadcrumb.events.button.new")}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            {data &&
              <DataGrid
                className="data-grid-custom-pagination"
                loading={isLoading}
                rows={data}
                columns={cols}
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSize={pageSizeDGs}
                rowsPerPageOptions={constPaginationDataGrid.defaultPageSizesArray}
                onPageSizeChange={(newPageSize) => {
                  setPageSizeDGs(newPageSize)
                }}
                pagination
              />
            }
          </Box>
        </Grid>
      </Grid>

      <MassiveAsyncModalAccept
        open={asyncModal}
        toggleModal={() => { setAsyncModal(false) }}
        modalText={props.location.state?.modal?.message}
      />

    </>
  );
}

export default EventsMain;
