import { Card, CardActionArea, FormControl, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function SelectImageField(props) {
  const { label, value, name, fieldValues, filters, onChange, continued = ()=>{}, objectType, setSkipFirstSelectImage, skipFirstSelectImage } = props;
  const [fieldValuesFiltered, setFieldValuesFiltered] = useState([])


  useEffect(() => {
    const fieldsFieltered = fieldValues?.filter((item) => {
      if (filters && filters[name]) {
        let filter = filters[name];
        let filtersFieldsValues = [];
        for (let conditionFieldTemp of filter.conditionField) {
          filtersFieldsValues.push(item[conditionFieldTemp]);
        }

        if (filter.func(filter.conditionValue, filtersFieldsValues) === false) {
          return false;
        }
      }
      return true
    })
    if(!skipFirstSelectImage && fieldsFieltered.length === 1 && objectType==="box"){
      setSkipFirstSelectImage(true)
      onChange(name, fieldsFieltered[0].key);
      continued();
    }

    setFieldValuesFiltered(fieldsFieltered)
  }, [fieldValues, filters, name])

  return (
    <FormControl fullWidth>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        className="font-weight-normal mb-4">
        {label}
      </Typography>
      <Grid spacing={2} container>
        {fieldValuesFiltered?.map((item) => {

          let logo = null;
          try {
            logo = require(`../../../Images/boxes/logos/${item.label}.png`);
          } catch (e) {
            logo = require('../../../Images/origino_logo.png');
          }

          return (
            <Grid item xs={12} xl={6}>
              <Card
                style={value === item.key ? {
                  border: "2px solid #3969e8",
                  boxShadow: "none",
                  borderRadius: "14px"
                } : {}}
                onClick={() => {
                  onChange(name, item.key);
                  continued();
                }}>
                <CardActionArea className="p-4 text-center font-weight-normal">
                  <img
                    style={{ maxWidth: '80px', minWidth: '80px' }}
                    src={logo.default}
                    alt="Origino"
                    className="mb-2 w-100"
                  />
                  <Typography variant="h6" component="p" color="text.primary" className=" mb-2">
                    {item.label && item.label.split('|').length === 1 ? item.label : ''}
                    {item.label && item.label.split('|').length === 2 ? (
                      <p>
                        {item.label.split('|')[0]}
                        <br />
                        <small>{item.label.split('|')[1]}</small>
                      </p>
                    ) : (
                      ''
                    )}
                  </Typography>
                  <Typography variant="caption" component="p" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </FormControl>
  );
}

export default SelectImageField;
